<template>
    <Transition name="slide-fade-down">
        <div
            v-if="props.openMenu"
            class="c-mega-menu fixed-element bg-light--gray"
        >
            <div class="c-layout-container h-100">
                <div class="column h-100">
                    <div class="col">
                        <div class="row justify-between">
                            <div class="col-auto">
                                <button
                                    class="c-button c-button--close"
                                    @click="toggleMenu(false)"
                                ></button>
                            </div>

                            <div class="col-auto">
                                <LangMenu />
                            </div>
                        </div>
                    </div>
                    <div class="col-8 z-index-30">
                        <div class="row flex-center h-100">
                            <div class="col-auto">
                                <MenuNavigation :menuType="'mainMenu'" />

                                <menu class="c-social-menu">
                                    <li><a href="https://de.linkedin.com/company/we-create-experiences" title="LinkedIn" target="_blank">LinkedIn</a></li>
                                    <li><a href="https://www.instagram.com/walbertschmitz/" title="Instagram" target="_blank">Instagram</a></li>
                                    <li><a href="https://www.youtube.com/c/Walbert-schmitzDe" title="Youtube" target="_blank">Youtube</a></li>
                                    <li><a href="https://www.pinterest.de/walbertschmitz/" title="Pinterest" target="_blank">Pinterest</a></li>
                                </menu>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row justify-end h-100">
                            <div class="col-auto self-end">
                                <ConfiguratorSwitch :darkMode="true" :menuSwitch="true" :option1="'Light Mode'" :option2="'Dark Mode'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
    const { $bus } = useNuxtApp();

    const props = defineProps({
        openMenu: Boolean
    })

    const toggleMenu = (value) => {
        $bus.$emit('toggleMenu', value)
    }
</script>