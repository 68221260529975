<template>
  <SlideUpTransition>
    <footer class="c-footer c-layout-container" :class="footerBackgroundColor">
      <div class="row justify-between c-company-container">
        <div class="col-12 col-sm-auto q-py-lg">
          <address>
            <a :href="'mailto:' + companyData.company_mail">
              {{ companyData.company_mail }} </a
            ><br />

            <a :href="'tel:' + companyData.company_phone">
              {{ companyData.company_phone }} </a
            ><br />

            <br />
            {{ companyData.company_name }}<br />
            {{ companyData.company_address }}<br />
            {{ companyData.company_postcode }} {{ companyData.company_city }}
          </address>
        </div>

        <div class="col-4 col-sm-auto c-logo-container">
          <PageLogo />
        </div>
      </div>

      <div class="row justify-between" style="align-items: baseline">
        <div class="col-auto">
          <MenuNavigation
            :menuType="'serviceMenu'"
          />
        </div>

        <div class="col-auto pt-4">
          <span>
            <strong>{{ year }} &#169; {{ companyData.company_name }}</strong>
          </span>
        </div>
      </div>
    </footer>
  </SlideUpTransition>
</template>

<script setup>
import { useGeneralStore } from "@/stores/general";
import { useMainContentStore } from "@/stores/mainContent";

const store = useGeneralStore();
const contentStore = useMainContentStore();
const { companyData } = storeToRefs(store);
const { pageContent } = storeToRefs(contentStore);

const { locale } = useI18n();
const footerBackgroundColor = ref("");

store.createCompanyData(locale.value);

watch(locale, () => {
  store.createCompanyData(locale.value);
});

watch(pageContent, () => {
  if (JSON.stringify(pageContent.value) !== "{}") {
    footerBackgroundColor.value = pageContent.value.pageMeta.footerBackgroundColor;
  }
});

// functionality for current year
let year = ref(null);

const currentYear = () => {
  const current = new Date();
  year.value = current.getFullYear();
};

currentYear();
</script>
