<template>
  <menu class="c-language-menu">
    <li>
      <NuxtLink
        :to="switchLocalePath('de')"
        :class="{ 'is-active': locale === 'de' }"
        @click="store.saveLanguage('de')"
      >
        DE
      </NuxtLink>
    </li>
    <li>
      <NuxtLink
        :to="switchLocalePath('en')"
        :class="{ 'is-active': locale === 'en' }"
        @click="store.saveLanguage('en')"
      >
        EN
      </NuxtLink>
    </li>
  </menu>
</template>

<script setup>
  import { useGeneralStore } from "@/stores/general";

  const { locale } = useI18n();
  const switchLocalePath = useSwitchLocalePath();

  const store = useGeneralStore();
</script>
