import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menuStore', {
    state: () => ({
        mainMenu: [],
        serviceMenu: []
    }),
    actions: {
       
        /**
         *
         * @param {*} payload
         */
        async setMenues (lang, processID) {
          const { data } = await useFetch(`https://administration.w-s.app/api/v1/services/menues/${processID}/${lang}`);

          if (data.value) {
            if (window.location.hostname === 'localhost' || window.location.hostname === 'walbert-schmitz.de') {
              const menues = data.value.menues;
              
              let filteredMainMenu = menues.mainmenu.filter((ele) => ele.pageStatus !== 'hidden');
              let filteredServiceMenu = menues.servicemenu.filter((ele) => ele.pageStatus !== 'hidden');
              
              this.mainMenu = filteredMainMenu;
              this.serviceMenu = filteredServiceMenu;
            } else if (window.location.hostname === 'staging.w-s.app') {
              const menues = data.value.menues;

              this.mainMenu = menues.mainmenu;
              this.serviceMenu = menues.servicemenu;
            }            
          }
        }
    }
})
