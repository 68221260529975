<template>
  <div class="cookie-banner__outer-box row flex-center">
    <div class="cookie-banner__box col-11 col-lg-10">
      <div class="cookie-banner">
        <div class="row language-row">
          <div class="col-auto">
            <LangMenu />
          </div>
        </div>

        <div class="row content-row">
          <h2 class="col-12">{{ $t("cookieContent.cookieHeadline") }}</h2>

          <div
            class="col-12 col-lg-10 cookie-banner__text"
            v-html="$t('cookieContent.cookieText')"
          ></div>
        </div>

        <div class="row justify-between settings-row">
          <div class="col-auto col-md-12 cookie-banner__options">
            <div class="round-checkbox">
              <label for="essential-cookies-checkbox">
                <input
                  type="checkbox"
                  id="essential-cookies-checkbox"
                  v-model="essentialCookies"
                  :disabled="true"
                  checked="checked"
                />
                {{ $t("cookieContent.cookieEssential") }}
              </label>
            </div>

            <div class="round-checkbox">
              <label for="marketing-cookies-checkbox">
                <input
                  type="checkbox"
                  id="marketing-cookies-checkbox"
                  :checked="marketingCheck === 'true'"
                  v-model="marketingCookies"
                />
                {{ $t("cookieContent.cookieFunctional") }}
              </label>
            </div>

            <div class="round-checkbox">
              <label for="analytics-cookies-checkbox">
                <input
                  type="checkbox"
                  id="analytics-cookies-checkbox"
                  :checked="analyticsCheck === 'true'"
                  v-model="analyticsCookies"
                />
                {{ $t("cookieContent.cookieAnalytics") }}
              </label>
            </div>
          </div>

          <div class="col-auto col-md-12 text-right cookie-banner__buttons">
            <button
              @click="
                toggleCookieBanner(false);
                cookie.acceptCookies(true);
              "
              class="c-button c-button--hollow accept"
            >
              {{ $t("cookieContent.acceptAll") }}
            </button>

            <button
              @click="
                toggleCookieBanner(false);
                cookie.acceptCookies();
              "
              class="c-button c-button--hollow accept"
            >
              {{ $t("cookieContent.cookieAccept") }}
            </button>

            <button @click="cookie.resetCookies()" class="c-button c-button--hollow">
              {{ $t("cookieContent.resetCookies") }}
            </button>
          </div>
        </div>
      </div>

      <button
        class="c-button c-button--close"
        @click="toggleCookieBanner(false)"
      ></button>
    </div>
  </div>
</template>

<script setup>
import { useGeneralStore } from "@/stores/general";
import { CookieHandling } from "@/assets/js/cookieConsent";

const { $bus } = useNuxtApp();

const generalStore = useGeneralStore();

const toggleCookieBanner = (value) => {
  $bus.$emit("openCookieConsent", { state: value });
};

const cookie = new CookieHandling(generalStore);

const analyticsCheck = ref(cookie.getCookie("analyticsCookies"));
const marketingCheck = ref(cookie.getCookie("marketingCookies"));
</script>
