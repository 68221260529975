<template>
  <Transition name="slide-down-transition">
    <slot v-if="show"></slot>
  </Transition>
</template>

<script setup>
const show = ref(false);

onMounted(() => {
  if (show) show.value = true;
});
</script>
