<template>
  <SlideDownTransition>
    <header
      class="c-header c-layout-container"
      :class="$route.path === '/universal-grid' ? 'sticky-element' : 'absolute-element'"
    >
      <div class="row justify-between c-header-innner">
        <div class="col-auto">
          <div class="c-menu__button">
            <button
              class="fixed c-button c-button--hollow desktop-menu-button"
              @click="toggleMenu(true)"
            >
              MENU
            </button>

            <button
              class="fixed c-button c-button--hollow mobile-menu-button flex-center"
              @click="toggleMenu(true)"
            >
              <span></span>
            </button>
          </div>
        </div>

        <div class="col-4 col-sm-auto c-logo-container">
          <PageLogo :logoColor="logoColor" />
        </div>
      </div>
    </header>
  </SlideDownTransition>
</template>

<script setup>
import { useRoute } from "vue-router";
import { useMainContentStore } from "~/stores/mainContent";

const { $bus } = useNuxtApp();

const $route = useRoute();
const store = useMainContentStore();
const { pageContent } = storeToRefs(store);

const toggleMenu = (value) => {
  $bus.$emit("toggleMenu", value)
};

const logoColor = ref();

watch(pageContent, () => {
  if (
    JSON.stringify(pageContent.value) !== "{}" &&
    pageContent.value.widgets[0].meta.options.backgroundColor === "bg-dark--blue"
  ) {
    logoColor.value = "white";
  } else {
    logoColor.value = "dark";
  }
});
</script>
