<template>
    <NuxtLink
        :to="localePath('/')"
    >
        <div 
            class="c-logo"
            :class="[
                props.logoColor === 'white' ? 'white-logo' : '',
                configStarted && !darkMode ? 'dark' : ''
            ]"
            v-html="logo"
        ></div>
    </NuxtLink>
</template>

<script setup>
    import logo from '~/assets/img/ws-logo-basic.svg'
    import { useGeneralStore } from "@/stores/general";

    const props = defineProps({
        logoColor: String
    })

    const store = useGeneralStore();
    const { darkMode, configStarted } = storeToRefs(store);
</script>
