<template>
    <div 
        class="c-additional-area"
        :class="darkMode ? 'bg-dark--blue' : 'bg-light--gray'"
    >
        <div class="section-container">
            <div class="row cta-text">
                <div class="col-12 col-sm-10 offset-md-1 col-md-10 offset-lg-1 col-lg-6">
                    <h2>{{ $t('footerHeadline') }}</h2>
                </div>
                <div class="col-12 col-sm-10 offset-sm-2 col-md-9 offset-md-3 col-lg-5 offset-lg-2">
                    <p class="lead-text">{{ $t('footerText') }}</p>
                </div>
            </div>
            <div class="row cta-button flex justify-end">
                <div class="col-auto">
                    <a 
                        class="call-to-action get-in-touch"
                        href="mailto:info@walbert-schmitz.de"
                    >
                        {{ $t('footerButton') }}
                    </a>
                </div>
            </div>

            <VideoItem
                :src="'/blobs/' + blobSrc + '.mp4'"
                :type="'mp4'"
                :alt="'Blob'"
                :class="'flow-video'"
            ></VideoItem>
        </div>
    </div>
</template>

<script setup>
    import { useGeneralStore } from "@/stores/general"

    const generalStore = useGeneralStore()
    const { blobSrc, darkMode } = storeToRefs(generalStore)
</script>

<style lang="scss">
    .c-additional-area {
        .section-container {
            aspect-ratio: 16/9;

            .cta-text {
                position: relative;
                z-index: 100;
            }

            .cta-button {
                @media screen and (max-width: 599px) {
                    justify-content: center;
                }
            }

            .media-container {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                height: 100%;

                video {
                    max-width: 100%;

                    @media screen and (max-width: 599px) {
                        max-width: 700px;
                    }
                }
            }
        }
    }
</style>